import React from 'react';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  Hits,
  Stats,
  CurrentRefinements,
  SearchBox,
  Pagination,
  RefinementList 
} from 'react-instantsearch-dom';


import PropTypes from 'prop-types';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './assets/img/logo-eb-white.png';



const searchClient = algoliasearch('XNLE8W6MIP', '8ba180d47302b213ea96e0bf2e554549');

function App() {
  return (
    <div>
      <header className="header">
        <img src={logo} width='100' alt='logo'></img>
        <h1 className="header-title">
            KBLI 2020
        </h1>
      </header>

      <div className="container">
        <InstantSearch searchClient={searchClient} indexName="KBLI2020.V1">

        <div className="row">
            <div className="col-md-4">
                
                <h5>Sektor</h5>
                <RefinementList attribute="sektor" />
                <h5>Skala</h5>
                <RefinementList attribute="skala" />
                <h5>Resiko</h5>
                <RefinementList attribute="resiko" />
            </div>


            <div className="col-md-8">
                <SearchBox className="searchbox" translations={{placeholder: 'Ketik bidang usaha, komoditi atau kode KBLI',}}/>
                <div className="row">
                    <div className="col-md-8"><CurrentRefinements /></div>
                    <div className="col-md-4"><Stats /></div>
                </div>
                
                <Hits hitComponent={Hit} />
                <div className="pagination"><Pagination /></div>
            </div>

        </div>     


          
        </InstantSearch>
      </div>
    </div>
  );
}

function Hit(props) {

    let sektor = props.hit.sektor;
    let kode = props.hit.kode;
    let editurl = "https://docs.google.com/forms/d/e/1FAIpQLSe6HEk39Uto66LoMJKE9OGoBnjisXYMzfKoHXoASapvOaaNkQ/viewform?usp=pp_url&entry.221631371="+sektor+"&entry.1245800662="+kode;
 
  return (
    <div class="col-lg-12 col-md-12 col-sm-12">
      <h5>Sektor {props.hit.sektor}</h5>
      <h4>
        Kode KBLI {props.hit.kode}
      </h4>
      <p dangerouslySetInnerHTML={{__html: props.hit._highlightResult.judul.value}} />
      <p><a href={editurl} target="_blank" rel="noopener noreferrer">Request Edit</a></p>
      <ul>
        <li><b>Ruang Lingkup </b> <span dangerouslySetInnerHTML={{__html: props.hit._highlightResult.lingkup.value}} /></li>
        <li><b>Skala Usaha </b> {props.hit.skala}</li>
        <li><b>Perizinan </b> {props.hit.perizinan}</li>
        <li><b>Resiko </b> {props.hit.resiko}</li>
        <li><b>Wilayah </b>{props.hit.parameter}</li>
        <li><b>Otoritas </b>{props.hit.wewenang}</li>
      </ul>

    </div>

  );
}

Hit.propTypes = {
  hit: PropTypes.object.isRequired,
};

export default App;
